/** @file Stale-while-revalidate data fetching strategy for Svelte. */

import { browser } from "$app/environment";

import ky, { HTTPError, TimeoutError } from "ky";
import { onDestroy } from "svelte";
import { get, type Readable, writable } from "svelte/store";

import { normalizeError } from "./toast";

const retryableStatusCodes = new Set([
  500, // gRPC: INTERNAL
  502, // Ingress or deployment issues
  503, // gRPC: UNAVAILABLE
  504, // Ingress or deployment issues
]);

type Context<T> = {
  data: Readable<T | undefined>;
  error: Readable<Error | undefined>;
  isValidating: Readable<boolean>;
  mutate: (initialData?: T, refetch?: boolean) => void;
};

function makeContext<T>(url: string): Context<T> {
  let abort = new AbortController();

  const data = writable<T | undefined>(undefined);
  const error = writable<Error | undefined>(undefined);
  const isValidating = writable<boolean>(false);

  const mutate = function mutate(initialData?: T, refetch = true) {
    abort.abort();
    if (initialData !== undefined) {
      data.set(initialData);
      error.set(undefined);
    }
    if (!refetch) return;

    const currentAbort = (abort = new AbortController());
    if (url && browser) {
      isValidating.set(true);
      (async () => {
        // always allow for one retry in case timeouts, since they often occur
        // as false positives after a computer goes to sleep or similar
        const MAX_ATTEMPTS = 2;
        for (let attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
          try {
            // We set the priority of the request to be "low" so that fetching
            // the bundle on page navigation is not queued behind SWR requests.
            // This improves navigation speeds.
            //
            // As of March 2024, `RequestInit.priority` is supported by all
            // major browsers except Firefox.
            const resp = await ky
              .get(url, {
                signal: currentAbort.signal,
                timeout: 25000,
                priority: "low", // May incorrectly show an error in VS Code, but it typechecks.
              })
              .json<T>();
            data.set(resp);
            error.set(undefined);
            isValidating.set(false);
          } catch (requestError: any) {
            if (
              (requestError instanceof TimeoutError ||
                (requestError instanceof HTTPError &&
                  retryableStatusCodes.has(requestError.response.status))) &&
              attempt < MAX_ATTEMPTS
            ) {
              continue;
            }
            if (!currentAbort.signal.aborted) {
              // resolve as error, unless the request was willfully aborted
              const message = await normalizeError(requestError);
              error.set(new Error(message, { cause: requestError }));
              isValidating.set(false);
            }
          }
          break;
        }
      })();
    }
  };

  // Note: We previously debounced the mutate() function before returning here
  // from makeContext() for performance. However, this isn't correct because it
  // subtly breaks some cases where we'd like to apply optimistic updates by
  // delaying the propagation of mutate().

  return { data, error, isValidating, mutate };
}

/** A cache for stale-while-revalidate requests. */
class SWRCache {
  map: Map<string, { context: Context<any>; referenceCount: number }>;

  constructor() {
    this.map = new Map();
  }

  /** Request an entry from the cache, increasing the reference count. */
  create<T>(key: string, trackReference: boolean = true): Context<T> {
    const result = this.map.get(key);
    if (result !== undefined) {
      if (trackReference) {
        result.referenceCount++;
      }
      return result.context;
    }
    const context = makeContext<T>(key);
    this.map.set(key, { context, referenceCount: trackReference ? 1 : 0 });
    return context;
  }

  /**
   * Drop a reference to a cache entry, decreasing the reference count.
   *
   * Contexts with a reference count of 0 are not fetched from the server when
   * they become invalidated.
   */
  drop(key: string) {
    const result = this.map.get(key);
    if (result) {
      result.referenceCount--;
      if (result.referenceCount === 0) {
        result.context.mutate(undefined, false);
      }
    } else {
      throw new Error(`Tried to drop missing key ${key} from SWR cache`);
    }
  }

  /** Mutate an entry, potentially triggering an automatic revalidation. */
  mutate(key: string, initialData?: any) {
    const result = this.map.get(key);
    if (result && result.referenceCount > 0) {
      result.context.mutate(initialData);
    }
  }

  mutateAll() {
    this.map.forEach((value) => {
      if (value && value.referenceCount > 0) {
        value.context.mutate();
      }
    });
  }
}

const cache = new SWRCache();

export type Options<T> = {
  readonly initialData?: () => T | undefined;
  readonly revalidateOnFocus?: boolean;
  readonly refreshInterval?: number;
  readonly ignoreDestroy?: boolean;
};

export type Result<T> = Context<T> & {
  update: (key: string | null | undefined, options?: Options<T>) => void;
  dispose: () => void;
};

/**
 * Reactively make GET requests to a specified resource, inspired by [SWR](https://swr.vercel.app).
 *
 * ### Usage example
 *
 * The `useSWR` function returns a collection of stores, which can be subscribed to.
 *
 * ```
 * import useSWR from "$lib/swr";
 *
 * export let name: string;
 *
 * const { data, error, mutate, update } = useSWR<SecretDetailResponse>();
 * $: update(`/api/secrets/${name}`);
 *
 * $: console.log($data);
 * $: console.log($error);
 *
 * function refresh() {
 *   mutate();
 * }
 * ```
 *
 * ### Simple requests
 *
 * If you're only looking for a simple request to a URL that's known at mount time and won't
 * change, you can just pass it to the initial function instead of `$: update()`.
 *
 * ```
 * import useSWR from "$lib/swr";
 *
 * const { data, error } = useSWR<SettingsResponse>("/api/user/settings");
 * ```
 */
function useSWR<T = any>(key?: string, options?: Options<T>): Result<T> {
  let url = "";

  let context: Context<T> | null = null;

  // Copy this store from the cached store.
  const data = writable<T | undefined>(undefined);
  const error = writable<Error | undefined>(undefined);
  const isValidating = writable<boolean>(false);
  const mutate = (initialData?: T) => {
    context?.mutate?.(initialData);
  };

  // Dispose handlers on each update call.
  let disposers: (() => void)[] = [];
  const dispose = () => {
    for (const handler of disposers) handler();
    disposers = [];
  };
  if (!options?.ignoreDestroy) {
    onDestroy(dispose);
  }

  const update = (key: string | null | undefined, options: Options<T> = {}) => {
    if (!browser) return;

    key = key ?? "";
    if (key !== url) {
      url = key;
      dispose();

      context = cache.create(key!);
      disposers.push(() => cache.drop(key!));

      if (options.initialData) {
        context.mutate(options.initialData());
      } else {
        context.mutate();
      }

      disposers.push(context.data.subscribe((v) => data.set(v)));
      disposers.push(
        context.error.subscribe((v) => {
          if (v) {
            data.set(undefined);
          }
          error.set(v);
        }),
      );
      disposers.push(
        context.isValidating.subscribe((v) => isValidating.set(v)),
      );

      if (options.revalidateOnFocus ?? true) {
        const onFocus = () => {
          if (!get(isValidating)) mutate();
        };
        window.addEventListener("focus", onFocus);
        disposers.push(() => window.removeEventListener("focus", onFocus));
      }

      if (options.refreshInterval) {
        const onRefresh = () => {
          if (!get(isValidating)) mutate();
        };
        const id = setInterval(onRefresh, options.refreshInterval);
        disposers.push(() => clearInterval(id));
      }
    }
  };

  if (key) {
    // Initial key, not reactive.
    update(key, options);
  }

  return {
    data,
    error,
    isValidating,
    mutate,
    update,
    dispose,
  };
}

/** Invalidate a key in the cache, triggering an automatic revalidation. */
export function mutate(key: string, initialData?: any) {
  cache.mutate(key, initialData);
}

/** Prefetch a key in the SWR cache, if it does not already exist. */
export async function prefetch(key: string) {
  const context = cache.create(key, false);
  if (get(context.data) === undefined) {
    context.mutate();
  }
}

/**
 * Invalidate all keys in the cache, triggering an automatic revalidation.
 *
 * Use this function _very sparingly_, as it essentially destroys all of the benefits of
 * SWR for caching requests and will make your code slow.
 */
export function mutateAll() {
  cache.mutateAll();
}

export default useSWR;
